import * as yup from "yup";
import { isEmpty } from "lodash";

import { ILanguages, TranslateFunc } from "Providers";

const multiLangValidation = {
    sv: {
        required: "* Obligatoriskt",
        onlyNumbers: "* Får bara innehålla siffror",
        atLeastTenNumbers: "* Minst 10 siffror"
    },
    en: {
        required: "* Required",
        onlyNumbers: "* May only contain numbers",
        atLeastTenNumbers: "* At least 10 digits"
    }
};

/**
 * Validates a phone number with a 1-3 digit country code and a 1-14 digit phone number.
 *
 * @param {string} [message] - Custom validation message
 * @returns {yup.StringSchema} - Yup string schema with phone number validation
 *
 * Regex explanation:
 * - ^: Start of the string
 * - \d{1,3}: 1 to 3 digits for the country code
 * - \d{1,14}: 1 to 14 digits for the phone number
 * - $: End of the string
 */
export const phoneNumberValidation = (message?: string) => {
    return yup.string().matches(/^\d{1,3}\d{1,14}$/, message);
};

const orgNoValidation = (orgNumber: string, userLanguage: ILanguages = "sv") => {
    const validationText = multiLangValidation[userLanguage];

    const isRequired = yup.string().required(validationText.required);

    if (!orgNumber) {
        return isRequired.matches(/^[0-9]*$/, validationText.onlyNumbers).min(10, validationText.atLeastTenNumbers);
    }
    return yup
        .string()
        .matches(/^[0-9]*$/, validationText.onlyNumbers)
        .min(10, validationText.atLeastTenNumbers);
};

const formatYupError = (err: yup.ValidationError) => {
    let errors = {};
    //use regex to filter errors with structure groupFieldName[00].fieldName
    const regex = /.+\[\d+\]..+$/;
    const objectShapeErrors: Array<any> = err.inner.filter(e => regex.test(e.path));

    const errorsObj =
        objectShapeErrors.reduce((acc, currE) => {
            const [groupFieldNameAndIdx, fieldName] = currE.path.split(".");
            const [groupFieldName, groupFieldIdx] = groupFieldNameAndIdx.slice(0, -1).split("[");

            if (!acc || !acc[groupFieldName]) {
                acc = { [groupFieldName]: [] };
            }
            acc[groupFieldName][groupFieldIdx] = {
                ...acc[groupFieldName][groupFieldIdx],
                [fieldName]: currE.message
            };
            return acc;
        }, {}) || {};

    err.inner
        .filter(e => !regex.test(e.path))
        .forEach(e => {
            errors = { [e.path]: e.message };
        });
    return isEmpty(errors) ? errorsObj : errors;
};

const getNestedRequiredFields = (
    fields: Record<string, yup.SchemaDescription | any>,
    prefix: string = ""
): Record<string, boolean> => {
    const requiredFields: Record<string, boolean> = {};

    Object.entries(fields).forEach(([key, field]) => {
        const fullPath = prefix ? `${prefix}.${key}` : key;
        if ("fields" in field) {
            const nestedFields = getNestedRequiredFields(field.fields, fullPath);
            Object.assign(requiredFields, nestedFields);
        } else if ("tests" in field) {
            const schemaDescription = field as yup.SchemaDescription;
            if (schemaDescription.tests.some(test => test.name === "required")) {
                requiredFields[fullPath] = true;
            }
        }
    });

    return requiredFields;
};

const getRequiredYupFields = (schema: yup.ObjectSchema): Record<string, boolean> => {
    const describedSchema = schema.describe();
    return getNestedRequiredFields(describedSchema.fields);
};

const ipValidation = (translate: TranslateFunc) => {
    return {
        receiptPrinter: yup.object().shape({
            ip: yup
                .string()
                .required(translate("formErrorMissing"))
                .matches(
                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    translate("ipWrongFormat")
                )
        })
    };
};

export { orgNoValidation, formatYupError, getRequiredYupFields, ipValidation };
