import moment from "moment";
import { TranslateFunc } from "LanguageProvider";
import { Subscription, CompanySubscription, SubscriptionCancellationEligibility } from "Types";

export const sortSubscriptions = (a: Subscription, b: Subscription) => {
    const defaultSortOrder = Number.MAX_SAFE_INTEGER;
    // Cant use `sortOrder || defaultSortIndex` here because sortOrder might be zero
    const aSortOrder = a.sortOrder == null ? defaultSortOrder : a.sortOrder;
    const bSortOrder = b.sortOrder == null ? defaultSortOrder : b.sortOrder;
    const sortOrderDiff = aSortOrder - bSortOrder;

    // Sort by name if sort indices are equivalent
    if (sortOrderDiff == 0) {
        return a.name > b.name ? 1 : -1;
    }

    return sortOrderDiff;
};

export const sortCompanySubscriptions = (a: CompanySubscription, b: CompanySubscription) => {
    const defaultSortOrder = Number.MAX_SAFE_INTEGER;
    // Cant use `sortOrder || defaultSortIndex` here because sortOrder might be zero
    const aSortOrder = a.subscription.sortOrder == null ? defaultSortOrder : a.subscription.sortOrder;
    const bSortOrder = b.subscription.sortOrder == null ? defaultSortOrder : b.subscription.sortOrder;
    const sortOrderDiff = aSortOrder - bSortOrder;

    // Sort by name if sort indices are equivalent
    if (sortOrderDiff == 0) {
        return a.subscription.name > b.subscription.name ? 1 : -1;
    }

    return sortOrderDiff;
};


/**
 * Calculates the date when the subscription can be cancelled based on the provided eligibility object
 * @param startDate  The start date of the subscription
 * @param eligibility The cancellation eligibility object
 * @returns  The date when the subscription can be cancelled or null if the eligibility is not recognized
 */
export function calculateCancellationEligibilityDate(
    startDate: Date,
    eligibility: SubscriptionCancellationEligibility
): Date | null {
    if (!eligibility) {
        return null;
    }

    const { type, durationMonths, fixedDate } = eligibility;

    switch (type) {
        // Example: add X months from "now"
        case "DURATION":
            if (durationMonths) {
                return moment(startDate)
                    .add(durationMonths, "months")
                    .toDate();
            }
            break;

        // Example: return the provided fixed date
        case "FIXED":
            if (fixedDate) {
                return moment(fixedDate).toDate();
            }
            break;
    }

    return null;
}


/**
 * Creates a string representing the binding period based on the provided subscription cancellation eligibility object
 * e.g. "3 months" or "until 2024-12-25"
 * This function translates the months and until strings
 * @param subscriptionCancellationEligibility the subscription cancellation eligibility object
 * @param translateFunction the translate function
 * @returns a string representing the binding period
 */
export const createBindingPeriodText = (subscriptionCancellationEligibility: SubscriptionCancellationEligibility | undefined | null, translateFunction: TranslateFunc): string | null => {
    const translatedMonthsString = translateFunction("months");
    const translatedUntilString = translateFunction("until");

    // Fall back, 3 months
    if (!subscriptionCancellationEligibility?.type) {
        return `{3} ${translatedMonthsString}`;
    }

    const bindingPeriodType = subscriptionCancellationEligibility?.type;
    switch (bindingPeriodType) {
        case "DURATION":
            return `${subscriptionCancellationEligibility?.durationMonths} ${translatedMonthsString}`;

        case "FIXED":
            return `${translatedUntilString} ${moment(subscriptionCancellationEligibility?.fixedDate).format("YYYY-MM-DD")}`;
    }

    return null;
}